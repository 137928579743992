<script setup lang="ts">
import AppTopBar from '../components/app/AppTopbar.vue'
import { useThemeStore } from '@/stores'

const themeStore = useThemeStore()
themeStore.setLight()
</script>

<template>
  <div class="wrapper">
    <Link
      rel="stylesheet"
      :href="
        themeStore.link ||
        'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/vela-blue/theme.css'
      "
    />

    <AppTopBar />
    <slot />
  </div>
</template>
<style scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>
